nav {
  background-color: var(--brand-black);
  display: flex;
  justify-content: space-between;
  padding: var(--spacer-base) var(--spacer-md) calc(var(--spacer-base) - var(--spacer-sm));
  flex-wrap: wrap;
}

nav ul {
  list-style-type: none;
  display: flex;
  gap: var(--spacer-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  margin: 0 0 var(--spacer-sm);
}

nav ul li {
  margin-top: 0;
}

nav a {
  text-decoration: none;
  color: var(--brand-white);
}
