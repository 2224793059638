footer {
  display: flex;
  justify-content: center;
  background-color: var(--brand-black);
  color: var(--brand-white);
  padding: var(--spacer-lg);
  line-height: var(--paragraph-line-height);
}

.footer__textlink {
  color: var(--brand-white);
}

.footer__pride {
  color: var(--brand-black);
  font-weight: var(--font-weight-bold);
  background: linear-gradient(to right, red, orange, yellow, green, blue, violet);
  text-shadow: #FFF 1px 1px 0.5rem;
}
