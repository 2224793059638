.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--brand-black);
  padding: var(--spacer-lg) var(--spacer-md) var(--spacer-lg) var(--spacer-md);
}

.header__wrapper {
  display: grid;
  margin-bottom: var(--spacer-base);
  max-width: var(--content-width-lg);
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
}

.header__image {
  border-radius: 100%;
}

.header__text > * + * {
  margin-top: var(--spacer-base);
}

.header__title,
.header__subtitle,
.header__subtitle a,
.header__quote {
  color: var(--brand-white);
}

.header__title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-line-height);
  text-align: center;
  margin-top: var(--spacer-base);
}

.header__subtitle {
  max-width: 20rem;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  line-height: var(--paragraph-line-height);
  text-align: center;
}

.header__quote {
  font-size: var(--font-size-base);
  max-width: 50rem;
}

.header__quote strong {
  float: right;
}

@media screen and (min-width: 52rem) {
  .header__wrapper {
    grid-gap: var(--spacer-lg);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .header__title {
    font-size: var(--font-size-3xl);
    text-align: left;
    margin-top: 0;
  }

  .header__subtitle {
    max-width: 20rem;
    margin-top: var(--spacer-xl);
    font-size: var(--font-size-base);
    text-align: left;
  }
}
