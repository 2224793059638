.flex-row {
  display: flex;
  gap: 1rem
}

.flex-center {
  display: flex;
  justify-content: center;
}

