@font-face {
  font-family: "Fixel";
  src: url("../fonts/fixel.woff2") format("woff2-variations");
  font-weight: 100 950;
  font-display: swap;
}

* {
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: "Fixel", sans-serif;
  /* fill the viewport, even when empty */
  min-height: 100vh;
}

html:focus-within {
  /* smooth scrolling */
  scroll-behavior: smooth;
}
