.button {
  display: inline-block;
  padding: var(--spacer-xs) var(--spacer-sm);
  background-color: var(--brand-red);
  color: var(--brand-white);
  font-weight: var(--font-weight-bold);
  line-height: var(--paragraph-line-height);
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
}

.button:hover {
  background-color: var(--brand-white);
  color: var(--brand-red);
  outline-color: var(--brand-red);
  outline-style: solid;
  transition: 0.5s ease-in-out;
}

.button:focus {
  background-color: var(--brand-white);
  color: var(--brand-red);
  outline-color: var(--brand-red);
  outline-style: solid;
  transition: 0.5 ease-in-out;
}

.button--inverted {
  background-color: var(--brand-white);
  color: var(--brand-red);
}
