:root {
  /* Colors */
  --brand-grey: #f8f8f8;
  --brand-black: #08172d;
  --brand-white: #fff;
  --brand-red: #9b111e;
  --brand-purple: #9660d7;

  /* Typography based on modular scale
   * https://www.modularscale.com/?1&rem&1.2  */
  --font-size-3xl: 4.3rem; /* 69px */
  --font-size-2xl: 2.986rem;
  --font-size-xl: 2.488rem;
  --font-size-lg: 2.074rem; /* 33px */
  --font-size-md: 1.728rem;
  --font-size-base: 1.2rem; /* 20px */

  --paragraph-line-height: 1.52;
  --heading-line-height: 1.2;

  --font-weight-bold: 950;

  /* Spacings also based on modular scale */
  --spacer-xxxxl: 10.699rem; /* ...px */
  --spacer-xxxl: 8.916rem; /* ...px */
  --spacer-xxl: 7.43rem; /* 119px */
  --spacer-xl: 3.583rem; /* 57px */
  --spacer-lg: 2.986rem; /* 48px */
  --spacer-base: 2.074rem; /* 33px */
  --spacer-md: 1.44rem; /* 23px */
  --spacer-sm: 1rem; /* 16px */
  --spacer-xs: 0.578rem; /* 9px */
  --spacer-xxs: 0.335rem; /* 5px */

  /* Content Width */
  --content-width-xl: 90.25rem; /* 1444 px Full Width */
  --content-width-lg: 60rem;
  --content-width-md: 50rem;
}
