table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: var(--brand-red);
  color: var(--brand-white);
  font-weight: var(--font-weight-bold);
}

tbody tr:not(:last-child) {
  border-bottom: 1px solid var(--brand-black);
}

tbody tr:nth-child(2n + 1) {
  background-color: var(--brand-grey);
}

th,
td {
  padding: var(--spacer-xs);
  text-align: left;
}
