a {
  color: var(--brand-black);
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

a:hover {
  color: var(--brand-red);
}

a:focus {
  color: var(--brand-red);
}
