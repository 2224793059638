main.home {
  display: flex;
  justify-content: center;
  background-color: var(--brand-grey);
}

main.page,
main.post {
  display: flex;
  max-width: var(--content-width-md);
  flex-direction: column;
  justify-content: center;
  padding: var(--spacer-xl) var(--spacer-sm) var(--spacer-xl) var(--spacer-sm);
  margin: 0 auto;
}

main.page > * + *,
main.post > * + * {
  margin-top: var(--spacer-sm);
}
