h1 {
  color: var(--brand-red);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-line-height);
}

h2 {
  color: var(--brand-red);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-line-height);
}

h3 {
  color: var(--brand-red);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-line-height);
}

h1, h2, h3 {
  text-wrap: balance;
}

@media screen and (min-width: 48rem) {
  h1 {
    font-size: var(--font-size-3xl);
  }

  h2 {
    font-size: var(--font-size-2xl);
  }
}
