.teaser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacer-xl) var(--spacer-sm) var(--spacer-xl) var(--spacer-sm);
  margin: 0 auto;
  max-width: var(--content-width-xl);
}

.teaser > * + * {
  margin-top: var(--spacer-sm);
}

.teaser-title {
  align-self: center;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-line-height);
  color: var(--brand-red);
}

.teaser-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacer-lg) var(--spacer-base);
  color: var(--brand-white);
  background-color: var(--brand-red);
}

.teaser-card__title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-line-height);
}

.teaser-card__title a {
  color: var(--brand-white);
}

.teaser-card__text {
  margin: var(--spacer-base) 0;
  font-size: var(--font-size-base);
  line-height: var(--paragraph-line-height);
}

@media screen and (min-width: 48rem) {
  .teaser-title {
    align-self: flex-start;
    font-size: var(--font-size-2xl);
  }

  .teaser-card {
    padding: var(--spacer-xl) var(--spacer-base);
  }

  .teaser-card__text {
    margin-top: var(--spacer-lg);
  }

  .teaser-card__title {
    font-size: var(--font-size-lg);
  }

  .teaser-card__button {
    padding: var(--spacer-xs);
    margin-top: var(--spacer-lg);
  }
}
